<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body" >
              <div class="row">
                <div class="col-md-12">
                  <div class="header_section">
                    <div v-if="reference !=='1'">
                      <router-link :to="{ name: 'giftcardDetail' ,params: {id: reference} }"><i class="glyphicon glyphicon-arrow-left"></i></router-link>
                    </div>
                    <div v-else>
                      <router-link :to="{ name: 'giftcards'}"><i class="glyphicon glyphicon-arrow-left"></i></router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-4">
                  <div class="setting_header">
                    <h2>Payment Details</h2>
                  </div>
                  <hr>
                  <div class="setting_content">
                    <strong>Payment: </strong>  {{formatPrice(voucher.amount)}} <br>
                    <strong>Balance: </strong> {{formatPrice(voucher.balance)}}<br>
                    <strong>Payment Status:</strong> {{voucher.payment_status}}
                  </div>
                  <div class="setting_header">
                    <h2>Voucher Details</h2>
                  </div>
                  <hr>
                  <div class="setting_content">
                    <strong>Code:</strong> {{voucher.code}} <br>
                    <strong>Title:</strong> {{voucher.short_description}} <br>
                    <strong>From Name:</strong> {{voucher.customer_first_name }} {{voucher.customer_last_name}} <br>
                    <strong>From Email:</strong> {{voucher.customer_email}}<br>
                    <strong>From Phone:</strong> {{voucher.customer_phone !=='' ? voucher.customer_phone : 'N/A'}} <br>
                    <strong>Recipient Name:</strong> {{voucher.recipient_first_name}}  {{voucher.recipient_last_name}}<br>
                    <strong>Recipient Email:</strong> {{voucher.recipient_email}}<br>
                    <strong>Recipient Phone:</strong> {{voucher.recipient_phone}} <br>
                    <strong>Expiry Date:</strong> 
                    <span >{{voucher.expiry_date}}</span>

                  </div>
                  <div class="mt-2" v-if="voucherStatus[voucher.status] != 'Void'">
                    <hr class="mb-2">
                    <span  v-if="!voucher.used && voucher.payment_status == 'COMPLETED' || !voucher.used && (voucher.bulk_generated && voucher.recipient_first_name !=='')" >
                      <button type="button" @click="print(voucher.code)" class="btn btn-success btn-sm mr-2">Print</button>
                      <button type="button" data-toggle="modal" data-target="#redeemModal" class="btn btn-success btn-sm mr-2">Redeem</button>
                    </span>
                    <!-- redeem modal -->
                    <div class="modal fade" id="redeemModal" tabindex="-1" role="dialog" aria-labelledby="redeemModalLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="redeemModalLabel">Redeem Voucher - <span class='voucher'>{{voucher.code}}</span></h5>
                            <button type="button" :id="'redeem_close_btn_'+voucher.code" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <form @submit.prevent="redeemVoucher(voucher.code)">
                            <div class="modal-body" style="padding:21px !important">
                              <input type="hidden" name="_token" >
                              <strong>Voucher amount: </strong><span class='voucher_amount'>{{formatPrice(voucher.amount)}}</span><br>
                              <strong>Available balance: </strong><span class='voucher_balance'>{{formatPrice(voucher.balance)}}</span>
                              <p></p>
                              <div class="form-group">
                                <label class="control-label">Amount</label>
                                <div>
                                  <input type="text" id="redeem_amount" class="form-control input-lg" v-model="redeemAmount">
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label">Check Id</label>
                                <div>
                                  <input type="text" id="redeeem_checkid" class="form-control input-lg" name="check_id" v-model="checkID">
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="redeen_location">Select Branch</label>
                                <select class="form-control" v-model="storeId" id="redeem_location">
                                  <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                                </select>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="submit" class="btn btn-primary redeem_submit"><span v-if="redeemingVoucher == voucher.code"><i class='fa fa-spinner fa-spin '></i></span> Redeem</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="row" v-if="voucherActivies.length > 0">
                <div class="col-md-12">
                  <div class="setting_header mb-4">
                    <h2>Redemption History</h2>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="table_section">
                    <table class="table  table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Location</th>
                          <th scope="col">Source</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Posted</th>
                          <th width="27%" scope="col">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="redemption in voucherActivies" :key="redemption.id">
                          <td>{{formatDate(redemption.created_at)}}</td>
                          <td>{{redemption.location}}</td>
                          <td>{{redemption.source}}</td>
                          <td>{{formatPrice(redemption.amount)}}</td>
                          <td>{{ formatPrice(redemption.balance)}}</td>
                          <td>{{redemption.posted}}</td>
                          <td>{{redemption.pos_message !== null ? redemption.pos_message : 'N/A'}}</td>
                        </tr>     
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main-loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required,email } from '@vuelidate/validators';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
  },
  setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            voucherStatus: ['Pending', 'Active', 'Void', 'Used'],
            loading: false,
            voucherID: this.$route.params.vouche_id,
            firstName: '',
            redeemAmount: '',
            recepientFirstName: '',
            recepientLastName:'',
            recepientEmail:'',
            recepientPhone:'',
            errors: false,
            voucherInfo: [],
            errorMsg: [],
            reference: '1',
            voucher: [],
            voucherActivies: [],
            regenerate: 0,
            redeemingVoucher: null,
            stores: []
        }
    },
    validations() {
        return {
          firstName: { required },
          lastName: { required },
          phone: { required },
          email: { required,email },
        }
    },
   
    mounted: async function (){
        this.getGiftVoucherInfo();
        this.getBranches()
    },
    methods: {
        async getGiftVoucherInfo() {
          const loader = document.getElementById('main-loader');
          loader.style.display = 'block';
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/redemption/${this.voucherID}?merchant_id=${this.merchantID}`;
            this.axios.get(url,config).then((response) => {
                this.voucherInfo = response.data.data
                if (response.status === 200) {
                    this.voucher = response.data.data.voucher
                    this.voucherActivies = response.data.data.activites
                    this.reference = response.data.data.voucher.reference
                    // 
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=>{
                this.loading = false;
                loader.style.display = 'none';
            })

        },
        redeemVoucher(code){
          this.errors =false;
          this.redeemingVoucher = code;
          const config = {
              headers: {
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${this.token}`
              }
          };
          const url = `${this.dashBaseUrl}/redeem`
          const payload = {
            'code': code,
            'location_id': this.storeId,
            'check_id' : this.checkID,
            'amount' : this.redeemAmount,
            'merchant_id':this.merchantID,
          };
          this.axios.post(url,payload,config).then((response) => {
            if(response.status === 200){
              toast.success('voucher was redeemed successfully');
            }
          }).catch((error) => {
            if(error.response.status === 400){
                  this.errors = true;
                    this.errorMsg = error.response.data.errors
            }
          }).finally(() => {
            this.getGiftVoucherInfo();
            this.redeemingVoucher = null;
            const closeBtn = document.getElementById('redeem_close_btn_'+code);
            if(closeBtn){
              closeBtn.click();
            }
          });
        },
        async getBranches() {
            const url = `${this.baseUrl}/branch?merchant_id=${this.merchantID}`;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    this.stores = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        print(code){
          const url = this.dashBaseUrl
          const baseUrl = url.replace("/api/v1", "/")
          const formatedUrl = baseUrl+"print?ref="+code+"&merchant="+this.merchantID+"&type=voucher";
          window.open(formatedUrl, '_blank');
        },
       
    },
     mixins: [ReusableDataMixins,ReusableFunctionMixins]
}
</script>
